import ClipboardJS from 'clipboard';

export default function Clipboards() {
  
  const clipboardBtns = new ClipboardJS('.clipboard-button');
  clipboardBtns.on('success', (e) => {
    const target = e.trigger;
    target.querySelector('.fas').classList.remove('fa-clipboard');
    target.querySelector('.fas').classList.add('fa-clipboard-check');

    setTimeout(() => {
      target.querySelector('.fas').classList.add('fa-clipboard');
      target.querySelector('.fas').classList.remove('fa-clipboard-check');
    }, 1500);
    
    e.clearSelection();
  });
}

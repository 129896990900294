/* --------------------------------- 
  Red Booth is comming from the british 
  famous telephone kiosks.
 --------------------------------- */
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import {makeElement, initSelectBox} from '../utils/functions';
import {getCountryName} from '../utils/countries';

export default function RedBooth(wrapper) {
  wrapper.style.opacity = 0;
  const input = wrapper.querySelector('input');
  const {metadata: {metadata: {countries}}} = new AsYouType('US');
  const initialState = {
    countries,
    country: 'US',
    code: '1',
    number: '',
    formatedNumber: null,
    value: null,
  }

  let state = {...initialState};
  state.value = `+${state.code}${state.number}`;
  
  if(input.value !== '')  {
    const parsedNumber = parsePhoneNumberFromString(input.value)
    if(parsedNumber) {
      const {country, nationalNumber, countryCallingCode} = parsedNumber;
      state = {...state, ...{
        country,
        number: nationalNumber,
        code: countryCallingCode,
        formatedNumber: parsedNumber.formatInternational(),
        value: parsedNumber.formatInternational(),
      }}
    } 
  }
  

  const updateUI = () => {
    flag.querySelector('i').setAttribute('class', `flag-icon flag-icon-${state.country.toLowerCase()}`);
    numberInput.value = state.value;
    input.value = state.value;
    input.dispatchEvent(new Event('input'));
  }

  const updateState = (newState) => {
    state = {...state, ...newState};
    const parsedNumber = parsePhoneNumberFromString(`+${state.code}${state.number}`);
    if(parsedNumber) state.formatedNumber = parsedNumber.formatInternational();
    else state.formatedNumber = null;
    state.value = state.formatedNumber === null ? `+${state.code}${state.number}` : state.formatedNumber;
    updateUI();
  }
  
  const makeRedBooth = () => {
    const flag = wrapper.querySelector('.phone-flag');
    const RedBoothEl = makeElement('DIV', {class: 'redbooth__wrapper'});
    const numberInput = makeElement('INPUT', {
      type: 'text',
      placeholder: 'Enter phone number here',
      class: 'number-input',
    })
    input.before(RedBoothEl);
    // Hiding main input
    input.setAttribute('tabindex', -1);
    input.style.transition = 'none';
    input.style.position = 'absolute';
    input.style.visibility = 'hidden';
    input.style.width = 0;
    input.style.height = 0;
    input.setAttribute('id', 'mainpn');

    RedBoothEl.appendChild(flag);
    RedBoothEl.appendChild(numberInput);

    const {countries} = initialState;
    
    const createCountrySelectBox = () => {
      const countryCodes = document.createElement('SELECT');
      Object.entries(countries).map(([label, [code]]) => {
        const option = makeElement('OPTION', {
          value: `${label}.${code}`,
          'data-icon': label,
          'data-icon-family': 'flag-icon',
        }, `${getCountryName(label) ? getCountryName(label) : label.toUpperCase()} (+${code})`);
        if(state && state.country && label === state.country.toUpperCase()) option.setAttribute('selected', '');
        countryCodes.appendChild(option);
      })
  
      return countryCodes;
    }
  
    const countryCodes = createCountrySelectBox();
    numberInput.before(countryCodes);
    initSelectBox(countryCodes, {placeholder: 'Country Code', icon: true});

    countryCodes.addEventListener('input', ({target: {value}}) => {
      const [country, code] = value.split('\.');
      updateState({code, country});
    });

    numberInput.addEventListener('input', ({target: {value}}) => {
      updateState({number: value.substr(state.code.length + 1, value.length)});
    });

    numberInput.addEventListener('blur', () => input.dispatchEvent(new Event('blur')));
    wrapper.style.opacity = 1;
    return {flag, RedBoothEl, countryCodes, numberInput};
  }

  

  const {flag, numberInput} = makeRedBooth();

  updateUI();
}
export default function Header() {
  const menuButton = document.querySelector('.mobile-menu-icon');
  if(!menuButton) return;
  const mobileMenu = document.querySelector('.mobile-menu');
  const icon = menuButton.querySelector('i.fas');

  menuButton.addEventListener('click', () => {
    mobileMenu.classList.toggle('active');
    icon.classList.toggle('fa-bars');
    icon.classList.toggle('fa-times');
    document.body.classList.toggle('prevent-scroll');
  });
}

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {initSelectBox} from '../utils/functions';

export const validationMessages = {
  validEmail: 'Enter a valid email address',
  notEmpty: 'This field is required',
  notEmptyGroup: 'These fields are required',
  specifySomething: 'Please specify your industry',
  confirmMessage: 'Passwords doesn\'t match',
  role: 'Please select one of the roles',
  passWeak: 'Password is too weak',
  phoneNumber: 'Please enter a valid number',
  validName: 'Please enter a valid name in latin characters',
  phonePlaceHolder: 'Enter number in international format, eg. +353 83 ...',
};

export function checkPass(val) {
  let no = 0;
  if (val !== '') {
    // If the password length is less than or equal to 6
    if (val.length <= 6) no = 1;
    // If the password length is greater than 6 and contain any lowercase alphabet or any number or any special character
    if (val.length > 6 && (val.match(/[a-z]/) || val.match(/\d+/) || val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/))) no = 2;
    // If the password length is greater than 6 and contain alphabet,number,special character respectively
    if (val.length > 6 && ((val.match(/[a-z]/) && val.match(/\d+/)) || (val.match(/\d+/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) || (val.match(/[a-z]/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)))) no = 3;
    // If the password length is greater than 6 and must contain alphabets,numbers and special characters
    if (val.length > 6 && val.match(/[a-z]/) && val.match(/\d+/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) no = 4;
  }
  const strength = document.querySelector('.strength');
  switch (no) {
    case 1:
      strength.innerHTML = '<i class="fas fa-poo" style="color: #8b4513"></i>';
      break;
    case 2:
      strength.innerHTML = '<i class="fas fa-frown-open" style="color: #D23B3B"></i>';
      break;
    case 3:
      strength.innerHTML = '<i class="fas fa-smile" style="color: #89a9ca"></i>';
      break;
    case 4:
      strength.innerHTML = '<i class="fas fa-fist-raised" style="color: #8abe06"></i>';
      break;
    default:
      strength.innerHTML = '';
      break;
  }

  return no;
}

export function isRequiredAndEmpty(input) {
  return input.required && input.value === '';
}

export function isNotRequiredAndEmpty(input) {
  return !input.required && input.value === '';
}

export function renderMessage(target, messages) {
  const errorField = target.querySelector('.errors');
  const inputs = [...target.querySelectorAll('input'), ...target.querySelectorAll('textarea'), ...target.querySelectorAll('select')];
  
  if (errorField) {
    errorField.innerHTML = '';
    for(let i in messages) {
      const message = messages[i];
      const messageWrapper = document.createElement('span');
      messageWrapper.innerHTML = message;
      errorField.appendChild(messageWrapper);
    }
  }
  
  // Updating the UI Classes
  if(messages.length > 0) {
    target.classList.add('error');
    inputs.forEach(input => input.classList.add('has-error'));
  } else {
    target.classList.remove('error');
    inputs.forEach(input => input.classList.remove('has-error'));
  }
}

export function mailIsValid(inputHolder) {
  let valid = true;
  const messages = [];
  const pattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm;
  const input = inputHolder.querySelector('input');
  if (!pattern.test(input.value.toLowerCase())) {
    valid = false;
    messages.push(validationMessages.validEmail);
  }
  
  return {
    isValid: valid,
    messages,
  }
}

export function nameIsValid(inputHolder) {
  let valid = true;
  const messages = [];
  const input = inputHolder.querySelector('input');
  if (!/[a-zA-z\s]+$/.test(input.value)) {
    valid = false;
    messages.push(validationMessages.validName);
  }
  return {
    isValid: valid,
    messages,
  }
}

export function confirmIsValid(inputHolder, type) {
  let confirmValid = true;
  const messages = [];
  const input = inputHolder.querySelector('input');
  const password = document.querySelector('#password');
  if (password.value !== input.value) {
    confirmValid = false;
    messages.push(validationMessages.confirmMessage);
  }
  if (isRequiredAndEmpty(input)) {
    confirmValid = false;
    messages.push(validationMessages.notEmpty);
  }
  // console.log(confirmValid, password.value, input.value);
  if(confirmValid && type === 'input') password.dispatchEvent(new Event('blur'));
  return {
    isValid: confirmValid,
    messages,
  }
}

export function passwordIsValid(inputHolder, type) {
  let passValid = true;
  const messages = [];
  const input = inputHolder.querySelector('input');
  const confirm = document.querySelector('#confirm');
  const passStrength = checkPass(input.value);  
  if (confirm && confirm.value !== input.value && confirm.value.length > 0) {
    passValid = false;
  }
  if (input.value !== '' && passStrength < 3) {
    passValid = false;
    messages.push(validationMessages.passWeak);
  }
  if (isRequiredAndEmpty(input)) {
    passValid = false;
    messages.push(validationMessages.notEmpty);
  }
  if(confirm && confirm.value.length > 0 && type === 'input') {
    const inputEvent = new Event('input');
    confirm.dispatchEvent(inputEvent);
  }
  return {
    isValid: passValid,
    messages,
  }
}

export function roleIsValid(inputHolder) {
  let formValid = true;
  const messages = [];
  const hidden = inputHolder.querySelector('.hidden_field_input') ? true : false;
  const name = inputHolder.querySelector('input').getAttribute('name');
  const checked = inputHolder.querySelector(`input[name="${name}"]:checked`);
  if(hidden && checked) {
    if( checked.parentElement.getAttribute('data-val') === 'other' && inputHolder.querySelector('.hidden_field_input').value === '' ) {
      formValid = false;
      messages.push(validationMessages.specifySomething);
    }
  } else if(checked === null) {
    formValid = false;
    messages.push(validationMessages.notEmpty);
  }
  return {
    isValid: formValid,
    messages,
  }
}

export function telephoneIsValid(inputHolder, type) {
  let formValid = true;
  const messages = [];
  const inputNumber = inputHolder.querySelector('#mainpn') || inputHolder.querySelector('input');
  let validNumber = false;
  let numberValue = inputNumber.value;
  const numberClass = parsePhoneNumberFromString(numberValue);
  if (numberClass !== undefined) {
    validNumber = numberClass.isValid() || numberClass.isPossible();
  }
  if (!validNumber) {
    formValid = false;
    messages.push(validationMessages.phoneNumber);
  }

  if(type === 'blur' && numberClass && numberClass.isValid()) {
    inputNumber.value = numberClass.formatInternational();
  }

  return {
    isValid: formValid,
    messages,
  }
}

export function inputIsValid(inputHolder, type) {
  let formValid = true;
  const messages = [];
  const select = inputHolder.querySelector('select');
  const input = inputHolder.querySelector('input');
  const textarea = inputHolder.querySelector('textarea');
  if (select && select.required) {
    if (select.value === '') {
      formValid = false;
      messages.push(validationMessages.notEmpty);
    }
  }
  if(input) {
    if(input.type === 'checkbox') {
      formValid = input.checked;
      messages.push(validationMessages.notEmpty);  
    } else if (isRequiredAndEmpty(input)) {
      formValid = false;
      messages.push(validationMessages.notEmpty);
    }
  }
  if(textarea && textarea.required && textarea.value.length <= 0) {
    formValid = false;
    messages.push(validationMessages.notEmpty);
  }
  return {
    isValid: formValid,
    messages,
  };
}

export function validationHOC(props) {
  const {rule, inputHolder, formProps, then} = props;
  const {switchValid, dismiss} = formProps;
  const input = inputHolder.querySelector('input') || inputHolder.querySelector('select') || inputHolder.querySelector('textarea');
  const name = (input.required || inputHolder.getAttribute('data-validation') !== null ) ? 
    inputHolder.getAttribute('data-validation') ? inputHolder.getAttribute('data-validation') : input.getAttribute('name') 
    : null;
  
  // Rule generally returns an array of messages and a isValid as a Bool
  if(name && dismiss.indexOf(name) < 0) {
    const {isValid} = rule(inputHolder);
    switchValid(isValid, name);
  } 

  const event = ({type}) => {
    const {messages, isValid} = rule(inputHolder, type);
    if(name && dismiss.indexOf(name) < 0) switchValid(isValid, name);
    renderMessage(inputHolder, messages);
  }
  if(input.getAttribute('type') === 'radio' || input.getAttribute('type') === 'checkbox') {
    const inputs = inputHolder.querySelectorAll('input');
    inputs.forEach(input => {
      input.addEventListener('blur', event);
      input.addEventListener('input', event);
    });
  } else {
    input.addEventListener('blur', event);
    input.addEventListener('input', event);
  }
  if(then) then(inputHolder);
}

export function isValid(form, options) {
  const {dismiss, selector, validator, defaultValidator} = options;
  
  const submit = form.querySelector('button[type="submit"]') || form.querySelector('input[type="submit"]');
  const inputs = [...form.querySelectorAll(selector)];

  const formProps = {
    validations: {},

    dismiss: [...dismiss],

    formValid: false,

    isValid: () => {
      const keys = Object.keys(formProps.validations);
      for(let i in keys) {
        if(formProps.validations[keys[i]] === false) {
          formProps.formValid = false;
          return false;
        }
      }
      formProps.formValid = true;
      return true;
    },

    switchValid: (state, name) => {
      formProps.validations[name] = state;
      formProps.switchSubmit();
    },

    switchSubmit: () => {
      formProps.isValid();
      if(formProps.formValid) submit.disabled = false
      else submit.disabled = true;
    },
    
  }

  inputs.forEach((inputHolder) => {
      const elem = inputHolder.querySelector('input') || inputHolder.querySelector('select') || inputHolder.querySelector('textarea');
      const validationType = elem.getAttribute('name');
      const inputValidation = validator.find(({inputs}) => inputs.indexOf( validationType ) >= 0)
      if(inputValidation) validationHOC({rule: inputValidation.rule, inputHolder, formProps, then: inputValidation.then});
      else validationHOC({rule: defaultValidator, inputHolder, formProps});
  });

  form.addEventListener('input', () => {
    formProps.switchSubmit();
  });


  form.addEventListener('submit', (e) => {
    if(formProps.formValid === false) e.preventDefault();
  })
}

export default function validateForm(form) {
  const selects = document.querySelectorAll('select');
  selects.forEach(item => {
    initSelectBox(item);
  });

  const options = {
    dismiss: [
      'account[org_name]',
    ],
    selector: '.input-holder',
    validator: [
      {inputs: ['account[user][email]', 'user[email]'], rule: mailIsValid},
      {inputs: ['account[user][full_name]', 'user[full_name]', 'account[user][org_name_dup]', 'user[org_name_dup]'], rule: nameIsValid},
      {inputs: ['account[user][password]', 'user[password]'], rule: passwordIsValid},
      {inputs: ['account[user][password_confirmation]', 'user[password_confirmation]'], rule: confirmIsValid},
      // {inputs: ['role', 'industry'], rule: roleIsValid},
      // {inputs: ['account[user][telephone]', 'user[telephone]'], rule: telephoneIsValid, then: RedBooth},
    ],
    defaultValidator: inputIsValid,
  }

  isValid(form, options);
}
import {getCookie, setCookie, generateUsername, sendReq} from '../utils/functions';
import RedBooth from './RedBooth';

export function SignupForm(form) {
  // MAPPING ROLE TO THE USECASE SELECT BOX
  const mapSelectBox = (value, name) => {
    const selectBox = form.querySelector(`select[name="account[user][${name}]"]`);
    selectBox.value = value;
    const event = new Event('input');
    selectBox.dispatchEvent(event);
  }

  [...form.querySelectorAll('.select-group label')].map((item) => {
    item.addEventListener('click', (ev) => {
      const val = item.getAttribute('data-val');
      const selectBox = item.parentElement.getAttribute('data-target') ? item.parentElement.getAttribute('data-target') : 'usecase';
      mapSelectBox(val, selectBox);
    });
  })

  // MAPPING ORGANIZATION
  if(form.querySelector('input[name="account[user][org_name_dup]"]')) {
    const usernameField = form.querySelector('input[name="account[user][username]"]')
    const email = form.querySelector('input[name="account[user][email]"]')
    form.querySelector('input[name="account[user][org_name_dup]"]').addEventListener('input', ({target: {value}}) => {
      const org = form.querySelector('#account_org_name');
      org.value = value;
      
      
      // GENERATING RANDOM USERNAME (ONLY ON NEWS API)
      ///////////////////////////////////////////////////
      
      if(usernameField && email && !form.classList.contains('textapi')) {
        usernameField.value = generateUsername(value, email.value)
        const event = new Event('input');
        usernameField.dispatchEvent(event);
      }
    });
  }

  // Fetching and Setting Name: 
  ///////////////////////////////////////////////////
  const mailInput = form.querySelector('input[name="account[user][email]"]')
  if(mailInput && !form.classList.contains('textapi')) {
    const nameInput = form.querySelector('input[name="account[user][full_name]"]')
    const resetAndToggleNameInput = hide => {
      nameInput.value = "";
      if(hide) nameInput.parentNode.parentNode.parentNode.classList.remove('show')
      else nameInput.parentNode.parentNode.parentNode.classList.add('show')
    }
    
    setTimeout(() => resetAndToggleNameInput(true), 10);
    
    const fetchNameValue = () => {
      if(!mailInput.classList.contains('has-error')) {
        sendReq('https://leadproxies.aylien.com/lead/', {
          email: mailInput.value,
          ping: true
        }).then(res => {
          const {exist, data} = JSON.parse(res.target.response);
          if(exist) {
            nameInput.parentNode.parentNode.parentNode.classList.remove('show')
            nameInput.value = data.name.fullName
            const event = new Event('input');
            nameInput.dispatchEvent(event);
            if(nameInput.classList.contains('has-error')) {
              nameInput.classList.remove(' has-error')
              resetAndToggleNameInput(false);
            }
          } else {
            resetAndToggleNameInput(false);
          }
        })
        .catch(() => {
          resetAndToggleNameInput(false);
        })
      }
    }
    
    mailInput.addEventListener('blur', fetchNameValue)
  }

  // Initializing Redbooth
  const phoneInput = form.querySelector('input[name="account[user][telephone]"]') || form.querySelector('input[name="user[telephone]"]')
  if(phoneInput) {
    const phoneWrapper = phoneInput.parentElement.parentElement
    RedBooth(phoneWrapper)
  }

  // FOCUS FIRST NOT HIDDEN INPUT
  [...form.querySelectorAll('input')].filter(item => !(item.hidden || item.type === 'hidden'))[0].focus();

  const submit = form.querySelector('button[type="submit"]');
  const submitText = submit.innerText;
  const toggleSubmit = (mode) => {
    if (mode) {
      submit.disabled = true;
      submit.innerHTML = '<i class="fas fa-spinner fa-spin"></i>';
    } else {
      submit.innerText = submitText;
    }
  }

  setCookie('isSubmiting', 'no', 10000);

  // SUBMITING TO HUBSPOT
  form.addEventListener('submit', (e) => {
    e.preventDefault();
    
    const email = form.querySelector('input[name="account[user][email]"]') ? form.querySelector('input[name="account[user][email]"]').value : null;
    const username = form.querySelector('input[name="account[user][username]"]') ? form.querySelector('input[name="account[user][username]"]').value : null;
    const telephone = form.querySelector('input[name="account[user][telephone]"]') ? form.querySelector('input[name="account[user][telephone]"]').value : null;
    const name = form.querySelector('input[name="account[user][full_name]"]') ? form.querySelector('input[name="account[user][full_name]"]').value : null;
    const organisation = form.querySelector('input[name="account[user][org_name_dup]"]') ? form.querySelector('input[name="account[user][org_name_dup]"]').value : null;
    const usecase = form.querySelector('select[name="account[user][usecase]"]') ? form.querySelector('select[name="account[user][usecase]"]').value : null;
    let industry = form.querySelector('select[name="account[user][industry]"]') ? form.querySelector('select[name="account[user][industry]"]').value : '';
    const product = form.classList.contains('textapi') ? 'textapi' : 'newsapi';

    if(document.querySelector('#other_industry') && document.querySelector('#other_industry').value !== '') industry = document.querySelector('#other_industry_value').getAttribute('data-expanded-value');

    setCookie('register_email', email, 100);
    setCookie('register_username', username, 100);
    setCookie('register_telephone', telephone, 100);
    setCookie('register_name', name, 100);
    setCookie('register_organisation', organisation, 100);
    setCookie('register_usecase', usecase, 100);
    setCookie('register_industry', industry, 100);
    setCookie('register_product', product, 100);
    setCookie('signup_submitted', true, 100);


    // Preventing account to submited twice
    if(getCookie('isSubmiting') === 'no') {
      toggleSubmit(true);
      setCookie('isSubmiting', 'yes', 100);
      form.submit();
      var submitTimeout = setTimeout(() => {
        setCookie('isSubmiting', 'no', 100);
      }, 1500);
    }
  });

  var selectGroup = [...form.querySelectorAll('.select-group')];
  if(selectGroup.length > 0) { 
    selectGroup.forEach(item => {
      if(item.classList.contains('with-hidden-input')) {
        const radios = item.querySelectorAll('input[type="radio"]');
        const hiddenInput = item.querySelector('input.input');
        hiddenInput.addEventListener('input', e => {
          e.target.parentElement.parentElement.parentElement.setAttribute('data-expanded-value', `other > ${e.target.value}`);
        });
        radios.forEach((radio, index) => (
          radio.addEventListener('change', e => {
            if(e.target.checked && e.target.parentElement.classList.contains('hidden_input')) {
              item.classList.add('hide-text');
            }
            else {
              item.classList.remove('hide-text');
            }
          })
        ));
      }
    })
  }
}

export function SignupFinalize() {
  const email = getCookie('register_email');
  const username = getCookie('register_username');
  const phone = getCookie('register_telephone');
  const name = getCookie('register_name');
  const company = getCookie('register_organisation');
  const role = getCookie('register_usecase');
  const industry = getCookie('register_industry');
  const product = getCookie('register_product');
  const fullName = name.split(' ');
  let firstname = fullName.shift();
  let lastname = fullName.join(' ');

  if(lastname === '') {
    lastname = `${firstname}`
    firstname = '';
  }

  const identify = {
    email,
    company,
    firstname,
    lastname,
    role,
    industry,
    phone,
    username,
  };

  if (product === 'newsapi') identify['newsapi_user'] = true;
  else identify['textapi_user'] = true;

  const properties = [];

  Object.entries(identify).map((item) => {
    properties.push({
      property: item[0],
      value: item[1],
    });
  });

  const callback = () => {
    setCookie('register_email', '', -10);
    setCookie('register_username', '', -10);
    setCookie('register_telephone', '', -10);
    setCookie('register_name', '', -10);
    setCookie('register_organisation', '', -10);
    setCookie('register_usecase', '', -10);
    setCookie('register_industry', '', -10);
    setCookie('register_product', '', -10);
    setCookie('signup_submitted', '', -10);
  }

  window.addEventListener('DOMContentLoaded', () => {
    if(location.pathname.includes('signup/success')) {
      const body = {
        email,
        username,
        firstname,
        lastname,
        company,
        phoneNumber : phone,
      }
      sendReq('https://leadproxies.aylien.com/lead/', body)
      .then(callback)
      .catch(err => err);
    }
  });
}
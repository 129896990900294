export default function SidebarNav() {
  const navList = document.querySelector('.navigation.navigation--list.navigation--list_left');
  // Returns if navlist is not presented
  if (navList === null) return;

  let links = navList.querySelectorAll('a');
  links = [...links];

  function is(path) {
    if (location.pathname === path) return true;
    return false;
  }

  links.map((link) => { 
    if (is(link.pathname)) link.classList.add('active');
  });
}
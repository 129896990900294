import {makeElement} from '../utils/functions';

export default function FlashMessages() {
  const flashMessage = document.querySelector('#flash-messages');
  const createFlashMessage = (message, type) => {
    const wrapper = makeElement('div', {
      class: `navbar navbar-fixed-top navbar-default alert alert-${type}`,
      'data-dismiss' : 'alert',
    });
    const container =  makeElement('div', {
      class: 'container',
    }, message);
    const button = makeElement('button', {
      type: 'button',
      class: 'close',
      'aria-hidden': 'true',
    }, '×');

    container.prepend(button);
    wrapper.appendChild(container);

    if(flashMessage) flashMessage.appendChild(wrapper);
    else throw Error('There\'s no flash message wrapper in the DOM');
    initMessage(wrapper);
  };

  window.createFlashMessage = createFlashMessage;
  
  if (flashMessage === null) return;

  function hideMessage(message) {
    message.classList.add('hide');
  }

  function killMessage(message) {
    message.remove();
  }

  const TIMER = 5000;
  const messages = flashMessage.querySelectorAll('.alert');

  function initMessage(message) {
    setTimeout(() => {
      hideMessage(message);
    }, TIMER, setTimeout(() => (
      killMessage(message)
    ), TIMER + 500));

    const button = message.querySelector('button');

    button.addEventListener('click', () => {
      hideMessage(message);
      setTimeout(() => killMessage(message), 500);
    });
  }

  if (messages.length > 0) {
    messages.forEach(element => initMessage(element));
  }
}
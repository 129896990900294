// Import CSS
import animate_css from 'animate.css/animate.min.css';
import scss from '../css/main.scss';

// Import Components
import FlashMessages from './components/FlashMessages';
import SidebarNav from './components/SidebarNav';
import CustomInputs from './components/CustomInputs';
import Clipboards from './components/Clipboards';
import Header from './components/Header';
import initForms from './components/Forms';
import {inlineScrolls, fixHubspotCTA} from './utils/functions';

// Log the environment
if (process.env.NODE_ENV !== 'production') {
  console.log(`ENVIRONMENT MODE: ${process.env.NODE_ENV.toUpperCase()}`);    
}

window.fixHubspotCTA = fixHubspotCTA;

document.addEventListener('DOMContentLoaded', () => {
  Header();
  FlashMessages();
  SidebarNav();
  CustomInputs();
  Clipboards();

  // Functions 
  inlineScrolls();
  initForms();
});

import {latinCharsMap} from './latinChars'

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function setCookie(name, value, minutes) {
  let expires = "";
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + (minutes * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${(value || '')}${expires}; path=/`;
}

export function inlineScrolls() {
  const smoothScrolls = [...document.querySelectorAll('.inline-link')];
  if(smoothScrolls.length > 0) {
    smoothScrolls.forEach(item => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        const destHash = e.target.tagName === 'A' ? e.target.hash : e.target.parentNode.hash;
        const dest = document.querySelector(destHash).getBoundingClientRect().y;
        window.scrollBy({
          top: dest,
          left: 0,
          behavior: 'smooth',
        })
      })
    })
  }
}

export function fixHubspotCTA({ctaId, ctaBody, className, ctaIcon}) {
  const hsCta = document.querySelectorAll(`.hs-cta-${ctaId}`);
  hsCta.forEach(cta => {
    let button = null;
    let icon;
    if(ctaIcon) {
      icon = document.createElement('I')
      icon.classList.add('fas', `fa-${ctaIcon}`);
    }
    const int = setInterval((button) => {
      button = cta.querySelector(`#hs-cta-${ctaId} .cta_button`);
      if(button) {
        clearInterval(int);
        button.innerHTML = ctaBody;
        if(ctaIcon) button.appendChild(icon);
        if (className.indexOf(' ') >= 0) {
          className.split(' ').map(className => button.classList.add(className));
        } else {
          button.classList.add(className);
        }
      }
    })
  })
}

export function makeElement(element, attributes, ...value) {
  const el = document.createElement(element);
  for (var attribute in attributes) {
    el.setAttribute(attribute, attributes[attribute]);
  }
  if (value !== null) el.innerText = value;
  return el;
}

export function notInBoundingClientRect(elem, x, y) {
	const {top, left, width, height} = elem.getBoundingClientRect();
	return ((top < y || top > y + height) && (left < x || left > x + width));
}

export function findParent(elem, query) {
	if(elem.tagName === 'HTML') return null;
	return elem.parentElement && elem.parentElement.classList.contains(query) ? elem.parentElement : findParent(elem.parentElement, query);
}

export function initSelectBox(select, options) {
	const parent = findParent(select, 'input-holder');
	const selectOptions = [];
	select.querySelectorAll('option').forEach(item => {
		if(item.value !== "") selectOptions.push(item);
	})
	const initialOption = selectOptions.find(item => item.hasAttribute('selected'));
	const initialValue = initialOption !== undefined ? initialOption.getAttribute('value') : null;
	

	const initialState = {
		init: true,
		open: false, 
		searchValue: null,
		newValue: null,
		options: selectOptions,
		selectedOption: null,
		selectedValue: null,
		activeOption: null,
		elements: null,
		placeholder: 'Please choose an option',
		icon: false,
	}

	let state = {...initialState, ...(options ? options : {})};

	function updateUI() {
		if(state.init) {
			state.init = false;
			hideBox(select);
			return makeSelectBox();
		}
		const {selectBox, selectWrapper, selectSelected, selectInput, selectDropdown} = state.elements;
		if(state.searchValue !== null) {
			filterDropdownItem(state.searchValue, selectDropdown);
		}
		if(state.selectedValue !== null) {
			selectSelected.innerText = state.selectedOption.innerText;
			select.value = state.selectedValue;
			if(state.newValue !== null) {
				select.dispatchEvent(new Event('input'));
				selectInput.dispatchEvent(new Event('blur'));
			}
			[...selectDropdown.querySelectorAll('.select__options')].map(item => item.classList.remove('select__options--selected'));
			state.selectedOption.classList.add('select__options--selected');
		}
		if(state.activeOption !== null) {
			[...selectDropdown.querySelectorAll('.select__options')].map(item => item.classList.remove('hover'));
			state.activeOption.classList.add('hover');
		}
		if(state.open === true) {
			selectWrapper.classList.add('select--open');
			parent.style.zIndex = '200';
		}
		if(state.open === false) {
			selectWrapper.classList.remove('select--open');
			selectInput.blur();
			parent.style.zIndex = '1';
		}
	}

	function updateState(newState) {
		state = {...state, ...newState}
		updateUI();
	}

	function filterDropdownItem(value) {
		const toggleDropdownItem = (item, mode) => {
			if(mode) {
				item.style.display = 'none';
				item.classList.add('hide');
			} else {
				item.style.display = 'flex';
				item.classList.remove('hide');
			}
		}

		const options = [...selectDropdown.querySelectorAll('.select__options')];
		const noResult = selectDropdown.querySelector('.no__result');
		options.map(item => {
			if (item.innerText.toLowerCase().indexOf(value.toLowerCase()) < 0) {
				toggleDropdownItem(item, true);
				if(selectDropdown.querySelectorAll('.hide').length === state.options.length) {
					noResult.style.display = 'flex';
				} else noResult.style.display = 'none';
			}
			else toggleDropdownItem(item, false);
		});
		if(value === '') {
			noResult.style.display = 'none';
			options.map(item => toggleDropdownItem(item, false));
		}
	}

	function hideBox(item) {
		item.setAttribute('tabindex', '-1');
		item.style.opacity = '0';
		item.style.position = 'absolute';
		item.style.padding = '0';
		item.style.margin = '0';
		item.style.width = '0';
		item.style.bottom = '2em';
		item.style.right = '2em';
		item.visibility = 'hidden';
	}

	function makeSelectBox() {
		const selectWrapper = makeElement('div', {
			class: 'select'
		});

		const selectBox = makeElement('div', {
			class: 'select__box'
		});

		const selectSelected = makeElement('div', {
			class: 'select__selected'
		}, state.placeholder);

		const selectInputWrapper = makeElement('div', {
			class: 'select__input'
		});

		const selectInput = makeElement('input', {
			type: 'text',
			placeholder: 'Looking for...',
		});

		const selectDropdown = makeElement('div', {
			class: 'select__dropdown'
		});

		const selectDropdownGroup = makeElement('div', {
			class: 'select__dropdowngroup'
		});
		

		state.options.map((item, index) => {
			const itemText = item.innerText;
			const itemValue = item.getAttribute('value');
			if(!itemValue) return;
			const option = makeElement('div', {
				class: 'select__options',
				'data-value': itemValue
			}, itemText);
			if(state.icon) {
				const family = item.getAttribute('data-icon-family');
				const iconName = item.getAttribute('data-icon').toLowerCase();
				const icon = makeElement('i', {
					class: `${family} ${family}-${iconName}`
				})
				option.appendChild(icon);
			}
			selectDropdownGroup.appendChild(option);
		});

		selectDropdown.appendChild(selectDropdownGroup);

		const emptyOption = makeElement('div', {
			class: 'no__result',
			style: 'display: none',
		}, 'Nothing found, try another query');

		const frownIcon = makeElement('i', {
			class: 'far fa-frown-open fa-2x',
		})

		emptyOption.appendChild(frownIcon);
		
		selectInputWrapper.appendChild(selectInput);
		selectDropdown.appendChild(emptyOption);
		selectBox.appendChild(selectSelected);
		selectBox.appendChild(selectInputWrapper);
		selectBox.appendChild(selectDropdown);
		selectWrapper.appendChild(selectBox);

		select.after(selectWrapper);

		return {selectInput, selectBox, selectWrapper, selectSelected, selectDropdown};
	}

	function handleEventListeners(elemnts) {
		const {selectBox, selectInput, selectDropdown} = elemnts;
		
		selectInput.addEventListener('focus', (e) => {
			e.stopPropagation();
			if(!state.open) setTimeout(() => updateState({open: true, newValue: null}), 1);
		})

		document.addEventListener('mousedown', (e) => {
			const {screenX, screenY, target} = e;
			if (state.open && document.activeElement === selectInput & notInBoundingClientRect(selectBox, screenX, screenY) && !target.classList.contains('select__options')) {
				updateState({open: false});
			}
		});


		selectInput.addEventListener('input', ({target}) => {
			updateState({searchValue: target.value});
		});

		const options = [...selectDropdown.querySelectorAll('.select__options')];

		const findFirstVisibleItem = (options) => {
			if(options[0].classList.contains('hide')) return findNextItem(options[0], options);
			const top = options[0].offsetTop;
			selectDropdown.scrollTop = top;
			return options[0];
		}

		const findLastVisibleItem = (options) => {
			const lastIndex = options.length - 1;
			if(options[lastIndex].classList.contains('hide')) return findPrevItem(options[lastIndex], options);
			const top = options[lastIndex].offsetTop;
			selectDropdown.scrollTop = top;
			return options[lastIndex];
		}

		const findNextItem = (current, options) => {
			const nextItem = current.nextElementSibling;
			if (nextItem === null) return findFirstVisibleItem(options);
			if (nextItem.classList.contains('hide')) return findNextItem(nextItem, options);
			const top = nextItem.offsetTop;
			selectDropdown.scrollTop = top;
			return nextItem;
		}

		const findPrevItem = (current, options) => {
			const prevItem = current.previousElementSibling;
			if (prevItem === null) return findLastVisibleItem(options);
			if (prevItem.classList.contains('hide')) return findPrevItem(prevItem, options);
			const top = prevItem.offsetTop;
			selectDropdown.scrollTop = top;
			return prevItem;
		}

		selectInput.addEventListener('keydown', ({code}) => {
			switch (code) {
			case 'ArrowDown': {
				if(state.activeOption) {
					const nextItem = findNextItem(state.activeOption, options);
					updateState({activeOption: nextItem });
				} else {
					const firstActiveItem = findFirstVisibleItem(options);
					updateState({activeOption: firstActiveItem })
				}
			}
				break;
			case 'ArrowUp': {
				if(state.activeOption) {
					const prevItem = findPrevItem(state.activeOption, options);
					updateState({activeOption: prevItem });
				} else {
					const firstActiveItem = findLastVisibleItem(options);
					updateState({activeOption: firstActiveItem })
				}
			}
				break;
			case 'Enter': {
				updateState({
					open: false, 
					newValue: state.activeOption.getAttribute('data-value'), 
					selectedOption: state.activeOption, 
					selectedValue: state.activeOption.getAttribute('data-value'),
				})
			}
				break;
			default:
				break;
			}
		});

		selectDropdown.addEventListener('click', (e) => {
			if(e.target.classList.contains('select__options')) {
				updateState({
					open: false, 
					newValue: e.target,
					selectedOption: e.target, 
					selectedValue: e.target.getAttribute('data-value'),
				});
			}
		});

		selectInput.addEventListener('blur', () => {
			setTimeout(() => {
				if(state.open && state.newValue === null) {
					updateState({open: false})
				}
			}, 50);
		});
	}
	
	const {selectWrapper, selectSelected, selectBox, selectInput, selectDropdown} = updateUI();
	state.elements = {selectWrapper, selectSelected, selectBox, selectInput, selectDropdown}
	handleEventListeners(state.elements);
	if(initialOption && initialValue) {
		updateState({
			selectedOption: initialOption,
			selectedValue: initialValue,
		})
	}
}


export function generateUsername(org, email) {
	const [name] = email.split('@');
	const username = `${org.split(" ").join("_")}_${name}_${parseInt(Math.random() * 100000)}`
	const validated = replaceLatinChars(username)
	return validated;
}

export const sendReq = (url, body) => {
	const parsedBody = JSON.stringify(body);
	return new Promise((res, rej) => {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', url);
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(parsedBody);
		xhr.onload = (response) => {
			res(response);
		};
		xhr.onerror = err => rej(err)
	})
}


export const replaceLatinChars = (str) => {
	let output = str;
	Object.entries(latinCharsMap).map(
		([char, eq]) => {
			output = output.split(char).join(eq);
		}
	)
	return output;
}
import { SignupForm, SignupFinalize } from '../components/SignupForm';
import validateForm from '../components/generalValidation';
import {getCookie, setCookie } from '../utils/functions';

export default function initForms() {
  const form = document.querySelector('.form-container');
  // if (form) Validation(form);
  if (form) validateForm(form);
  if (form && form.id === 'signup_form') SignupForm(form);
  if (getCookie('signup_submitted')) SignupFinalize();
  if (getCookie('form_submited') === 'yes') setCookie('form_submited', 'no', 100);
}
